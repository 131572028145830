#official-rules p {
  @apply mb-4 pl-8;
}

#official-rules a {
  @apply underline;
}

#official-rules ol {
  @apply list-decimal pl-6;
}

#official-rules ol li {
  @apply mb-4 pl-2;
}

#official-rules table {
  @apply border border-black w-full mb-4 ml-4;
}

#official-rules table tr {
  @apply border border-black;
}

#official-rules table tr td {
  @apply border border-black px-2 py-2;
}

#official-rules table tr td:first-child {
  @apply w-36;
}

#official-rules table tr td p {
  @apply mb-0;
}
